import React, { useState, useEffect } from 'react'
import { InView, useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import Img from 'gatsby-image'
import theme from './../../styles/theme'

import Range from './Range'
import Slide from './Slide'
import Card from './Card'

const Wrapper = styled.div`
    width: 100%;

    display: none;
    
    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const Inner = styled(motion.div)`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    padding: 0 3rem 6rem 3rem;

    ${props => props.theme.above.desktop`
        flex-direction: row;

        width: 100%;
        max-width: 120rem;

        margin: 0 auto;
        padding: 20rem 0;
    `}
`

const Stack = styled(motion.div)`
    position: relative;

    width: 100%;
    max-width: 25rem;

    padding: 6rem 0;

    ${props => props.theme.above.desktop`
        width: 40%;
        max-width: none;

        margin-left: 5%;
        margin-right: 5%;

        padding: 0;
    `}
`

const StackInner = styled.div`
    position: relative;

    width: 100%;
    padding-top: 144%;
`

const Copy = styled(motion.div)`
    position: relative;

    width: 100%;

    ${props => props.theme.above.desktop`
        width: 40%;
        
        margin-left: 5%;
        margin-right: 5%;
    `}
`

const SlideWrapper = styled.div`
    position: absolute;

    top: 0;
    left: 0;

    transform: rotate(${props => props.rotation}deg);

    width: 100%;
    height: 100%;

    opacity: ${props => props.visible ? 1 : 0};

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${props => props.theme.colors.darkMedium};
        opacity: 0.9;
    }
`

const StyledSlide = styled(Img)`
    width: 100%;
    height: 100%;

    // border: 0.5rem solid ${props => props.theme.colors.white};
`

const StyledRange = styled(Range)`
    width: 100%;
`

const SliderWrapper = ({
    data,
    className
}) => {

    const [ref, inView] = useInView({
        threshold: 0.2,
        triggerOnce: true
    })

    const start = (data[0].items.length / 2) - 1
    const [values, setValues] = useState([start])
    const [isMobile, setIsMobile] = useState(false)

    let years = []
    
    data[0].items.forEach(({ member_year }) => {
        years.push(member_year)
    })

    const checkSize = () => {
        if (typeof window != 'undefined') {
            setIsMobile(window.innerWidth < theme.breakpoints.desktop ? true : false)
        } else {
            setIsMobile(false)
        }
    }

    useEffect(() => {
        checkSize()
    }, [])

    return (
        <Wrapper ref={ref} className={`generations-slider ${className && className}`} name='generations-slider'>
            <Inner>
                <Stack
                    animate={inView ? 'after' : 'before'}
                    variants={{
                        before: {
                            opacity: 0,
                            rotate: -5,
                            scale: 1.1
                        },
                        after: {
                            opacity: 1,
                            rotate: 0,
                            scale: 1
                        }
                    }}
                    transition={{
                        duration: 1,
                        damping: 100,
                        stiffness: 100,
                        ease: 'circOut'
                    }}
                >
                    <StackInner>
                        {/* {data[0].items.slice(0, 3).map(({
                            member_image,
                            rotation_degree
                        }, index) =>
                            <SlideWrapper 
                                key={index} 
                                rotation={rotation_degree}
                                visible={Math.round(values[0]) != index}
                            >
                                <StyledSlide
                                    fluid={member_image.fluid}
                                    alt={member_image.alt}
                                />
                            </SlideWrapper>
                        )} */}
                        {data[0].items.map(({
                            member_image,
                            rotation_degree
                        }, index) =>
                            <Slide
                                key={index}
                                image={member_image}
                                rotation={rotation_degree}
                                visible={Math.round(values[0]) == index}
                            />
                        )}
                    </StackInner>
                </Stack>
                <Copy
                    animate={inView ? 'after' : 'before'}
                    variants={{
                        before: {
                            opacity: 0,
                            y: -100
                        },
                        after: {
                            opacity: 1,
                            y: 0
                        }
                    }}
                    transition={{
                        duration: 1,
                        damping: 100,
                        stiffness: 100,
                        ease: 'circOut'
                    }}
                >
                    {data[0].items.slice(0).map(({
                        member_name,
                        member_description,
                        member_lifetime
                    }, index) => 
                        <Card
                            key={index}
                            name={member_name}
                            description={member_description}
                            lifetime={member_lifetime}
                            visible={Math.round(values[0]) == index}
                            isMobile={false}
                        />
                    )}
                </Copy>
                <StyledRange
                    values={values}
                    setValues={setValues}
                    labels={years}
                    total={data[0].items.length}
                    animate={inView ? 'after' : 'before'}
                />
            </Inner>
        </Wrapper>
    )
}

export default SliderWrapper
