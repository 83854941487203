import React, { forwardRef } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import styled from 'styled-components'

import Line from './../Line'

const Item = styled(motion.div)`
    width: 100%;
    min-height: 20rem;

    padding: 3rem;

    text-align: center;

    ${props => props.theme.above.desktop`
        top: 50%;
        left: 0;

        transform: translateY(-50%);

        padding: 0;

        text-align: left;
    `}
`

const StyledLine = styled(Line)`
    margin-right: 1rem;

    &.line {
        display: none;
    }

    ${props => props.theme.above.desktop`
        &.line {
            display: inline-block;
        }
    `}
`

const Subtitle = styled.div`
    margin-bottom: 1rem;

    span {
        display: inline-block;
        vertical-align: middle;
        
        font-family: ${props => props.theme.fontFamily.circularBook};
    }

    ${props => props.theme.above.desktop`
        margin-bottom: 2rem;
    `}
`

const Title = styled.h2`
    margin-bottom: 1rem;
`

const Description = styled.div`
    width: 100%;
    max-width: 40rem;

    margin: 0 auto;

    ${props => props.theme.above.desktop`
        margin: 0;
    `}
`

const Card = ({
    visible, 
    className,
    name, 
    description,
    lifetime,
    isMobile,
    type
}, ref) => {

    return (
        <AnimatePresence>
            {visible && (
                <Item 
                    ref={ref}
                    key={name.text}
                    className={className}
                    initial={{
                        opacity: 0,
                        top: isMobile ? 0 : '50%',
                        position: 'absolute'
                    }}
                    animate={{
                        opacity: 1,
                        position: isMobile ? 'relative' : 'absolute'
                    }}
                    exit={{
                        opacity: 0,
                        top: isMobile ? 0 : '50%',
                        position: 'absolute'
                    }}
                    transition={{
                        duration: 0.35,
                        damping: 100,
                        stiffness: 50,
                        ease: 'circOut'
                    }}
                >
                    <Subtitle>
                        <StyledLine />
                        <span>{lifetime.replace('-', '/')}</span>
                    </Subtitle>
                    <Title>{name.text}</Title>
                    <Description dangerouslySetInnerHTML={{ __html: description.html }} />
                </Item>
            )}
        </AnimatePresence>
    )
}

export default forwardRef(Card)
