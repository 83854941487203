import React, { useContext } from "react"
import styled from "styled-components"
import { Range, Direction, getTrackBackground } from "react-range"
import { motion } from "framer-motion"

import { DictionaryContext } from "./../../contexts/dictionary"
import theme from "./../../styles/theme"

import DragSvg from "./../../graphics/button-drag.svg"

const Wrapper = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    margin: 5rem 0 3rem 0;
    padding: 0 3rem;

    ${props => props.theme.above.desktop`
        margin: 14rem 0 0 0;
    `}
`

const Title = styled.span`
    display: none;

    font-family: ${props => props.theme.fontFamily.circularBook};

    ${props => props.theme.above.desktop`
        display: block;
    `}
`

const Track = styled.div`
    width: 100%;

    ${props => props.theme.above.desktop`
        max-width: 80rem;
        margin: 0 4rem;
    `}
`

const Line = styled.div`
    height: 0.2rem;
    width: 100%;

    transition: all 0.2s ${props => props.theme.transitions.cubic};
`

const Thumb = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 5rem;
    height: 5rem;

    border-radius: 100%;
    background-color: ${props => props.theme.colors.white};

    color: ${props => props.theme.colors.dark};

    // transition: all 0.2s ${props => props.theme.transitions.cubic};
`

const Tooltip = styled.div`
    position: absolute;

    bottom: -2.5rem;
    left: 50%;

    transform: translate(-50%, 0);

    width: 10rem;

    text-align: center;

    font-family: ${props => props.theme.fontFamily.circularBook};
    font-size: 1.2rem;
    letter-spacing: 0.05rem;

    color: ${props => props.theme.colors.white};

    ${props => props.theme.above.desktop`
        bottom: -3rem;

        font-size: 1.5rem;
    `}
`

const StyledIcon = styled(DragSvg)`
    width: 1.3rem;
    height: 2.5rem;

    transform: rotate(90deg);
    transform-origin: center;

    path {
        fill: currentColor;
    }
`

const StyledRange = ({
    className,
    values,
    total,
    labels,
    setValues,
    animate,
}) => {
    const { colors } = theme
    const dictionary = useContext(DictionaryContext)

    return (
        <Wrapper
            className={className}
            animate={animate}
            variants={{
                before: {
                    opacity: 0,
                    y: 100,
                },
                after: {
                    opacity: 1,
                    y: 0,
                },
            }}
            transition={{
                duration: 1,
                damping: 100,
                stiffness: 100,
                ease: "circOut",
            }}
        >
            <Title>{labels[0]}</Title>
            <Range
                values={values}
                direction={Direction.Right}
                step={0.01}
                min={0}
                max={total - 1}
                onChange={values => setValues(values)}
                renderTrack={({ props, children }) => (
                    <Track
                        onMouseDown={props.onMouseDown}
                        onTouchStart={props.onTouchStart}
                    >
                        <Line
                            ref={props.ref}
                            style={{
                                background: getTrackBackground({
                                    values: values,
                                    colors: [colors.white, colors.darkLightest],
                                    min: 0,
                                    max: total - 1,
                                    direction: Direction.Right,
                                }),
                            }}
                        >
                            {children}
                        </Line>
                    </Track>
                )}
                renderThumb={({ props, isDragged }) => (
                    <Thumb {...props} dragged={isDragged}>
                        <Tooltip className="tooltip">
                            {isDragged
                                ? labels[Math.round(values[0])]
                                : dictionary.drag_me}
                        </Tooltip>
                        <StyledIcon />
                    </Thumb>
                )}
            />
            <Title>{new Date().getFullYear()}</Title>
        </Wrapper>
    )
}

export default StyledRange
