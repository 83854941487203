import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import Img from 'gatsby-image'

const ImageWrapper = styled(motion.div)`
    position: relative;

    padding: 0 1.5rem;

    ${props => props.theme.above.desktop`
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        padding: 0;
    `}
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    border: 0.5rem solid ${props => props.theme.colors.white};

    transform: rotate(${props => props.rotation}deg);

    picture {
        img {
            opacity: 1 !important;
        }
    }
    
    ${props => props.theme.above.desktop`
        ${props.shadow == 'true' && `
            box-shadow: 0 0 5rem rgba(0, 0, 0, 0.5);
        `}
    `}
`

const Slide = ({
    visible,
    className, 
    image,
    type,
    rotation
}) => {

    if (type == 'carousel') {
        return (
            <ImageWrapper
                className={className}
                key={image.alt}
            >
                <StyledImage
                    className='image'
                    fluid={image.fluid}
                    alt={image.alt}
                    rotation={rotation}
                />
            </ImageWrapper>
        )
    } else {
        return (
            <AnimatePresence>
                {visible && (
                    <ImageWrapper 
                        className={className}
                        key={image.alt}
                        initial={{
                            opacity: 0,
                            scale: 0.95
                        }}
                        animate={{
                            opacity: 1,
                            scale: 1
                        }}
                        exit={{
                            opacity: 0,
                            scale: 0.95
                        }}
                        transition={{
                            duration: 0.35,
                            damping: 100,
                            stiffness: 50,
                            ease: 'circOut'
                        }}
                    >
                        <StyledImage
                            className='image'
                            fluid={image.fluid}
                            alt={image.alt}
                            rotation={rotation}
                            style={{
                                opacity: 1,
                            }}
                            imgStyle={{
                                opacity: 1,
                            }}
                            placeholderStyle={{
                                opacity: 0
                            }}
                            shadow='true'
                        />
                    </ImageWrapper>
                )}
            </AnimatePresence>
        )
    }
}

export default Slide
