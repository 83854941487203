import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { InView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

import Swiper from 'react-id-swiper'
import 'swiper/css/swiper.css'

import ButtonCircle from './../Buttons/ButtonCircle'

import Slide from './Slide'
import Card from './Card'

const Wrapper = styled.div`
    display: block;

    position: relative;

    width: 100%;

    ${props => props.theme.above.desktop`
        display: none;
    `}
`

const CarouselWrapper = styled.div`
    position: relative;
`

const Copy = styled.div`
    position: relative;
`

const StyledButtonCircle = styled(ButtonCircle)`
    position: absolute;

    top: 50%;
    
    transform: translateY(-50%);

    z-index: 2;

    .circle {
        width: 4rem;
        height: 4rem;

        background-color: rgba(255, 255, 255, 0.25);
        border-radius: 100%;
    }

    .top, 
    .bottom {
        display: none;
    }

    /* .icon {
        path {
            fill: ${props => props.theme.colors.darkStrong};
        }
    } */

    &.navigation-left {
        left: 2.5rem;
    }

    &.navigation-right {
        left: initial;
        right: 2.5rem;
    }

    &:active {
        .circle {
            background-color: #fff;
        }

        .icon {
            path {
                fill: ${props => props.theme.colors.darkLightest};
            }
        }
    }
`

const ImageWrapper = styled(motion.div)`
    position: relative;

    ${props => props.theme.above.desktop`
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        padding: 0;
    `}
`

const StyledImage = styled(Img)`
    width: 100%;
    height: 100%;

    picture {
        img {
            opacity: 1 !important;
        }
    }
`

const Carousel = ({
    className,
    data
}) => {

    const [swiper, updateSwiper] = useState(null)
    const [currentIndex, updateCurrentIndex] = useState(0)

    const params = {
        centeredSlides: true,
        initialSlide: 0,
        loop: true,
        preloadImages: false,
        grabCursor: true,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        getSwiper: updateSwiper
    }

    const goNext = () => {
        if (swiper !== null) {
            swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (swiper !== null) {
            swiper.slidePrev()
        }
    }

    const updateIndex = useCallback(() => updateCurrentIndex(swiper.realIndex), [
        swiper
    ])

    useEffect(() => {
        if (swiper !== null) {
            swiper.on('slideChange', updateIndex)
        }

        return () => {
            if (swiper !== null) {
                swiper.off('slideChange', updateIndex)
            }
        }
    }, [swiper, updateIndex])

    return (
        <Wrapper className={className}>

            <CarouselWrapper>
                <StyledButtonCircle className='navigation-left' type='flat-arrow' direction='left' onClick={() => goPrev()} />
                <StyledButtonCircle className='navigation-right' type='flat-arrow' direction='right' onClick={() => goNext()} />
                <Swiper {...params}>
                    {data[0].items.slice(0).reverse().map(({ member_image }, index) =>
                        <ImageWrapper
                            className={className}
                            key={index}
                        >
                            <StyledImage
                                className='image'
                                fluid={member_image.fluid}
                                alt={member_image.alt}
                            />
                        </ImageWrapper>
                    )}
                </Swiper>
            </CarouselWrapper>

            <Copy>
                {data[0].items.slice(0).reverse().map(({
                    member_name,
                    member_description,
                    member_lifetime
                }, index) =>
                    <Card
                        key={index}
                        name={member_name}
                        description={member_description}
                        lifetime={member_lifetime}
                        visible={currentIndex == index}
                        isMobile={true}
                    />
                )}
            </Copy>

        </Wrapper>
    )
}

// class Carousel extends React.Component {

//     state = {
//         currentIndex: 0,
//         copyHeight: 0,
//         responsive: {
//             0: {
//                 items: 1
//             }
//         },
//         stagePadding: {
//             paddingLeft: 30,
//             paddingRight: 30
//         },
//         carouselInView: false
//     }

//     onSlideChanged = (e) => this.setState({ currentIndex: e.item })
//     slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })
//     slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

//     render() {

//         const { currentIndex, stagePadding, responsive } = this.state
//         const { className } = this.props

//         return (
//             <Wrapper className={className}>

//                 <CarouselWrapper>
//                     <AliceCarousel
//                         buttonsDisabled={true}
//                         dotsDisabled={true}
//                         duration={500}
//                         swipeDisabled={true}
//                         slideToIndex={currentIndex}
//                         onSlideChanged={this.onSlideChanged}
//                         stagePadding={stagePadding}
//                         responsive={responsive}
//                     >
//                         {this.props.data[0].items.slice(0).reverse().map(({ member_image }, index) =>
//                             <ImageWrapper
//                                 className={className}
//                                 key={index}
//                             >
//                                 <StyledImage
//                                     className='image'
//                                     fluid={member_image.fluid}
//                                     alt={member_image.alt}
//                                 />
//                             </ImageWrapper>
//                         )}
//                     </AliceCarousel>
//                     <StyledButtonCircle className='navigation-left' type='flat-arrow' direction='left' onClick={() => this.slidePrev()}/>
//                     <StyledButtonCircle className='navigation-right' type='flat-arrow' direction='right' onClick={() => this.slideNext()}/>
//                 </CarouselWrapper>

//                 <Copy>
//                     {this.props.data[0].items.slice(0).reverse().map(({
//                         member_name,
//                         member_description,
//                         member_lifetime
//                     }, index) =>
//                         <Card
//                             key={index}
//                             name={member_name}
//                             description={member_description}
//                             lifetime={member_lifetime}
//                             visible={currentIndex == index}
//                             isMobile={true}
//                         />
//                     )}
//                 </Copy>

//             </Wrapper>
//         )
//     }
// }

export default Carousel