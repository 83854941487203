import React, { useContext } from 'react'
import { InView, useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { scroller } from 'react-scroll'

import { DictionaryContext } from './../../contexts/dictionary'

import Slider from './Slider'
import Carousel from './Carousel'
import TitleWithButton from './../TitleWithButton'

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;

    position: relative;

    overflow: hidden;
`

const StyledTitleWithButton = styled(TitleWithButton)`
    ${props => props.theme.above.desktop`
        max-width: 80rem;

        margin-bottom: 0;
        padding: 6rem 3rem;

        text-align: left;

        .description {
            max-width: 100%;
        }
    `}
`

const Introduction = styled.div`
    padding: 3rem 0;

    ${props => props.theme.above.desktop`
        padding: 12rem 0;
    `}
`

const HistoryPage = ({
    lang,
    className,
    sectionInView,
    inViewTreshold,
    data: {
        content_title,
        content_subtitle,
        content_description,
        family
    }
}) => {

    const dictionary = useContext(DictionaryContext)

    const [ref, inView] = useInView({
        threshold: 0.15,
        triggerOnce: true
    })

    return (
        <Wrapper ref={ref} className={className} name='generations'>
            <InView treshold={inViewTreshold} onChange={(inView, entry) => inView && sectionInView && sectionInView()}>
                <Introduction className='generations-introduction'>
                    <StyledTitleWithButton
                        lang={lang}
                        title={content_title.text}
                        subtitle={content_subtitle.text}
                        description={content_description.html}
                        button={{
                            type: 'arrow',
                            direction: 'down',
                            label: dictionary.explore_more,
                            onClick: () => (
                                scroller.scrollTo('generations-slider', {
                                    duration: 1500,
                                    delay: 100,
                                    smooth: 'easeInOutQuint'
                                })
                            )
                        }}
                        animate={inView ? 'after' : 'before'}
                    />
                </Introduction>
                <Slider data={family} />
                <Carousel data={family} />
            </InView>
        </Wrapper>
    )
}

export default HistoryPage
